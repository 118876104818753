.btn-actions {
	border: 1px solid #edf2f7;
	padding: 5px 10px;
	background-color: #edf2f7;
	color: #000;
	margin-right: 5px;
	border-radius: 5px;
	font-weight: 500;
}

.btn-actions:hover {
	background-color: #cbd5e0;
	transition: all 0.3s ease-in;
}

.module-test-result {
	box-shadow: 0px 16px 21px rgb(0 0 0 / 10%);
	border-radius: 10px;
	overflow: hidden;
}

.module-test-result svg {
	height: 20px;
	width: 20px;
}

.module-test-result-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #0e3b68;
	padding: 15px 20px;
	color: #fff;
}

.module-test-result-header p {
	font-size: 1.2rem;
}

.module-test-result-header.test_passed {
	border-bottom: 4px solid #009879;
}

.module-test-result-header.test_failed {
	border-bottom: 4px solid rgb(254, 110, 135);
}

.module-test-result-header.test_failed path {
	fill: rgb(254, 110, 135);
}

.module-test-result-list {
	list-style-type: none;
	padding: 20px;
	max-height: 450px;
	overflow: auto;
	font-size: 1rem;
}

.module-test-result-list > li:first-child {
	color: #95959c;
	padding: 10px;
}

.module-test-result-list > li:not(:first-child) {
	border-bottom: 1px solid #1976d2;
	padding: 10px;
}
.module-test-title {
	display: flex;
	align-items: center;
	gap: 10px;
}

.module-test-result-list li {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.module-test-result-list .ptz-focus-values {
	display: block !important;
}

.module-test-result-list .ptz-focus-values div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.open_camera-list {
	display: flex;
	flex-direction: column;
}

.open_camera-list-section {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.remote_list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
	padding: 10px;
}
.remote_list li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #1d1e29;
}

.testing-form {
	padding: 20px;
	width: 70%;
	border-radius: 10px;
	border: 2px solid #cbd5e0;
	height: max-content;
}

.testing-form label,
.session-form label {
	margin-bottom: 0;
	margin-top: 8px;
}
.session-form {
	width: 80%;
}

.testing-results-table {
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	width: 50%;
	height: auto;
	border: 1px solid #009879;
	padding: 20px;
	border-radius: 10px;
	flex-grow: 1;
}
.testing-results-table ul {
	list-style: none;
}

.testing-results-table li {
	border-radius: 3px;
	padding: 15px 20px;
	display: grid;
	grid-template-columns: 50px 1fr 1fr 1fr;
}

.package-testing-results-table {
	width: 50%;
	height: max-content;
	border: 1px solid #cbd5e0;
	flex-grow: 1;
}

.package-testing-results-table table {
	width: 100%;
}

.package-testing-results-table tr {
	border-bottom: 1px solid #95959c;
}
.package-testing-results-table table thead {
	background-color: #009879;
	color: #fff;
}
.package-testing-results-table table thead,
.package-testing-results-table table tbody {
	height: 50px;
}
.package-testing-results-table th,
.package-testing-results-table td {
	text-align: left;
	padding: 0 10px;
}
.table-header {
	background-color: #009879;
	color: #ffffff;
}
.table-row {
	background-color: #fff;
	box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}
.authorization-code {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-right: 10px;
}

.authorization-code input {
	height: 30px;
	width: 150px;
}
.authorization-code button {
	padding: 2px 5px;
}

.packaging-session-form {
	padding: 20px;
	width: 60%;
	border-radius: 10px;
	border: 1px solid #cbd5e0;
	height: max-content;
}

.packaging-session-form label {
	color: #009879;
	margin: 0;
}

.create-shipping-request {
	width: 80%;
}
.create-shipping-request label {
	margin: 0;
	color: #009879;
}
.shipping-status_select {
	border: 1px solid #1b6ec2;
	padding: 5px 10px;
	background-color: transparent;
	color: #000;
	margin-right: 5px;
	border-radius: 5px;
	cursor: pointer;
}

.custom-link {
	display: flex;
	gap: 5px;
	align-items: center;
	height: max-content;
	width: max-content;
	background-color: #edf2f7;
	padding: 10px;
	border-radius: 3px;
	font-weight: 500;
	transition: all 0.3s ease-in-out;
}

.custom-link:hover {
	background-color: #cbd5e0;
}

.custom-select {
	height: 1.9rem;
	border: 1px solid #cbd5e0;
	padding: 0px 5px;
}
.custom-select:focus {
	border: 2px solid #1b6ec2;
}
.datepicker {
	height: 1.9rem;
	border: 1px solid #cbd5e0;
	padding: 0px 5px;
	width: 100%;
	background-color: transparent;
}

.full-device_list {
	width: 70%;
	overflow: auto;
	border: 1px solid #cbd5e0;
}
.full-device_list table {
	border-collapse: collapse;
	margin: 25px 0;
	font-size: 0.9em;
	font-family: sans-serif;
	width: 100%;
	box-shadow: 0 0 20px rgb(0 0 0 / 15%);
}
.full-device_list table thead tr {
	background-color: #1976d2;
}
.full-device_list table thead tr th {
	text-align: left;
	color: #fff;
}
.full-device_list table thead {
	position: sticky;
	top: 0;
	z-index: 222;
}
.full-device_list table {
	margin: 0;
}
.chart-preferences {
	border: 1px solid #95959c;
	padding: 10px;
	width: 30%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 300px;
}

.fullscreen-chart {
	height: 100vh !important;
	width: 100vw !important;
	z-index: 500;
	position: absolute;
	top: 0;
	left: 0;
	padding: 35px 20px;
}
.line-chart_container {
	padding: 35px 20px;
	border: 1px solid #95959c;
	margin-top: 10px;
	position: relative;
	height: 600px;
	width: 100%;
}
.scale-icon:hover {
	transform: scale(1.1);
	cursor: pointer;
}
.pinned-charts_container {
	border: 1px solid #95959c;
	padding: 30px 20px;
	height: 600px;
	margin-top: 15px;
}
.pinned-charts {
	display: flex;
	gap: 10px;
	height: 90%;
}
.pinned-chart {
	position: relative;
	height: 100%;
	width: 50%;
}
.pinned-charts :first-child.pinned-chart {
	border-right: 2px solid #95959c;
}
.custom-legend {
	margin: 15px 0;
	max-height: 25px;
}
.custom-legend span {
	height: 18px;
	width: 30px;
}
.react-datepicker-popper {
	z-index: 233 !important;
}
